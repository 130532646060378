import React, { useState, useEffect } from 'react';
import styles from "./homeVideo.module.css";
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import { useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

const HomeVideo = () => {
  const reduxData = useSelector((state) => state.website);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 1450);




  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1450);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const videoUrl = `https://connollysapi-n476.onrender.com/images/${reduxData.data.heroVideo}`;

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;
    videoElement.oncanplaythrough = () => setIsVideoLoaded(true);
  }, [videoUrl]);

  return (
    <div className={styles.carouselCont}>
      {isVideoLoaded ? (

        <>

          {
            isWideScreen ?


              (

                <>
                  <BackgroundVideo video={videoUrl} />
                  <div className={styles.homeAbso}>
                    <p className={styles.homeSpecialP}> </p>
                  </div>

                </>



              ) :

              (

                <>

                  <div

                    className={styles.mediaContainer}

                  >
                    <img src={ `https://connollysapi-n476.onrender.com/images/${reduxData.data.mediaQueryImg}`} alt="title"/>

                  </div>


                  <div className={styles.homeAbso}>
                    <p className={styles.homeSpecialP}> {reduxData.data.landingHeroTitle}</p>
                  </div>


                </>



              )




          }

        </>






      ) : (
        <div className={styles.preloaderContainer}>
          <ClipLoader />
        </div>
      )}
    </div >
  );
};

export default HomeVideo;
