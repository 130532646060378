import React, { useEffect, useRef } from 'react'
import styles from "./homeMain.module.css"
import { useSelector } from 'react-redux'

const HomeMain = () => {


    const descRef = useRef([]);
    const boutonRef = useRef([]);
    const imgRef = useRef([]);
    const reduxData = useSelector((state) => state.website);

    function splitTextIntoParagraphs(text) {
        const words = text.split('.');
        const paragraphs = [];
        let currentParagraph = '';


        words.forEach(word => {
            if ((currentParagraph + word).length <= 350) {
                currentParagraph += (currentParagraph === '' ? '' : ' ') + word;

            } else {
                paragraphs.push(currentParagraph);


                currentParagraph = word;

            }
        });

        if (currentParagraph !== '') {
            paragraphs.push(currentParagraph);
        }


        let newArray = paragraphs.slice(0, 2)

        if (newArray[newArray.length - 1] !== undefined) {
            newArray[newArray.length - 1] = newArray[newArray.length - 1].concat("...")

        }





        return newArray.map((paragraph, index) => (
            <p key={index} className={styles.desc} >
                {paragraph}
            </p>
        ));
    }


    useEffect(() => {
        const appearOptions = {
            threshold: 0.11,
        };

        const appearOnScroll = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.appear);
                    observer.unobserve(entry.target);
                }
            });
        }, appearOptions);

        descRef.current.forEach((desc) => {
            appearOnScroll.observe(desc);
        });

        boutonRef.current.forEach((bouton) => {
            appearOnScroll.observe(bouton);
        });

        imgRef.current.forEach((img) => {
            appearOnScroll.observe(img);
        });


        return () => {
            appearOnScroll.disconnect();
        };
    }, []);

    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
    const preloadImage = (url) => {
        const img = new Image();
        img.src = url;
    };

    // Preload the image



    return (
        <div className={styles.container}>

            <div className={styles.top}>
                <p className={styles.specialP} id={styles.idFive}>{reduxData.data.landingTitle1}</p>
                <p className={styles.desc}>{ reduxData.data.landingDesc1 && reduxData.data.landingDesc1}.</p>

            </div>
            <div className={styles.wrapper}>

                <div className={styles.row} ref={(el) => (descRef.current[0] = el)}>
                    <div className={styles.imgCont}>
                        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.landingImg1}`} alt="A view of Connolly's restaurant" />
                    </div>
                    <div className={styles.txtBox}>
                        <div className={styles.hr}>
                            <hr />
                        </div>
                        <p className={styles.specialP}>{reduxData.data.landingTitle2}</p>
                       <p className={styles.desc}> { reduxData.data.landingDesc2 && reduxData.data.landingDesc2}</p>

                        {reduxData.data.landingButton1 === "Menu" && (
                            <a className={styles.a} href="/menus"> <button className={styles.giftBtn}>SEE MENUS</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "Gallery" && (

                            <a className={styles.a} href="/gallery"> <button className={styles.giftBtn}>GALLERY</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "ContactUs" && (

                            <a className={styles.a} href="/contact"> <button className={styles.giftBtn}>CONTACT US</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "Book" && (

<a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "Experience" && (
                            <a className={styles.a} href="/chefs-table-experience"> <button className={styles.giftBtn}>CHEF'S TABLE</button></a>

                        )

                        }

                        {reduxData.data.landingButton1 === "Careers" && (
                            <a className={styles.a} href="/careers"> <button className={styles.giftBtn}>CAREERS</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "Music" && (
                            <a className={styles.a} href="/music-at-connollys"> <button className={styles.giftBtn}>WHAT'S ON?</button></a>

                        )


                        }

                        {reduxData.data.landingButton1 === "Wine" && (
                            <a className={styles.a} href="/wine-experience-room"> <button className={styles.giftBtn}>WINE CAVE</button></a>


                        )

                        }

                        <div className={styles.hr}>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className={styles.row} >
                    <div className={styles.imgCont} id={styles.idOne} ref={(el) => (imgRef.current[0] = el)} >
                        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.landingImg2}`} alt="A view of Jason O'Neill working at the grill." />
                    </div>
                    <div className={styles.txtBox} id={styles.idTwo} ref={(el) => (boutonRef.current[0] = el)}>
                        <div className={styles.hr}>
                            <hr />
                        </div>
                        <p className={styles.specialP}>{reduxData.data.landingTitle3}</p>
                        <p className={styles.desc}>{ reduxData.data.landingDesc3 &&reduxData.data.landingDesc3}</p>
                        



                        {reduxData.data.landingButton2 === "Menu" && (
                            <a className={styles.a} href="/menus"> <button className={styles.giftBtn}>SEE MENUS</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "Gallery" && (

                            <a className={styles.a} href="/gallery"> <button className={styles.giftBtn}>GALLERY</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "ContactUs" && (

                            <a className={styles.a} href="/contact"> <button className={styles.giftBtn}>CONTACT US</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "Book" && (

<a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "Experience" && (
                            <a className={styles.a} href="/chefs-table-experience"> <button className={styles.giftBtn}>CHEF'S TABLE</button></a>

                        )

                        }

                        {reduxData.data.landingButton2 === "Careers" && (
                            <a className={styles.a} href="/careers"> <button className={styles.giftBtn}>CAREERS</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "Music" && (
                            <a className={styles.a} href="/music-at-connollys"> <button className={styles.giftBtn}>WHAT'S ON?</button></a>

                        )


                        }

                        {reduxData.data.landingButton2 === "Wine" && (
                            <a className={styles.a} href="/wine-experience-room"> <button className={styles.giftBtn}>WINE CAVE</button></a>


                        )

                        }

                        <div className={styles.hr}>
                            <hr />
                        </div>

                    </div>
                </div>
                <div className={styles.row} ref={(el) => (descRef.current[1] = el)}>
                    <div className={styles.imgCont}>
                        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.landingImg3}`} alt="One of our amazing dishes" />
                    </div>
                    <div className={styles.txtBox}>
                        <div className={styles.hr}>
                            <hr />
                        </div>
                        <p className={styles.specialP}>{reduxData.data.landingTitle4}</p>

                       <p className={styles.desc}> { reduxData.data.landingDesc4 && reduxData.data.landingDesc4} </p>

                        {reduxData.data.landingButton3 === "Menu" && (
                            <a className={styles.a} href="/menus"> <button className={styles.giftBtn}>SEE MENUS</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "Gallery" && (

                            <a className={styles.a} href="/gallery"> <button className={styles.giftBtn}>GALLERY</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "ContactUs" && (

                            <a className={styles.a} href="/contact"> <button className={styles.giftBtn}>CONTACT US</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "Book" && (

<a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "Experience" && (
                            <a className={styles.a} href="/chefs-table-experience"> <button className={styles.giftBtn}>CHEF'S TABLE</button></a>

                        )

                        }

                        {reduxData.data.landingButton3 === "Careers" && (
                            <a className={styles.a} href="/careers"> <button className={styles.giftBtn}>CAREERS</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "Music" && (
                            <a className={styles.a} href="/music-at-connollys"> <button className={styles.giftBtn}>WHAT'S ON?</button></a>

                        )


                        }

                        {reduxData.data.landingButton3 === "Wine" && (
                            <a className={styles.a} href="/wine-experience-room"> <button className={styles.giftBtn}>WINE CAVE</button></a>


                        )

                        }

                        <div className={styles.hr}>
                            <hr />
                        </div>
                    </div>
                </div>
                <div className={styles.row} >
                    <div className={styles.imgCont} ref={(el) => (imgRef.current[1] = el)} >
                        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.landingImg4}`} alt="Musicians in situ" />
                    </div>
                    <div className={styles.txtBox} ref={(el) => (boutonRef.current[1] = el)}>
                        <div className={styles.hr}>
                            <hr />
                        </div>
                        <p className={styles.specialP}>{reduxData.data.landingTitle5}</p>
                        <p className={styles.desc}>{ reduxData.data.landingDesc5 && reduxData.data.landingDesc5}</p>
                        {reduxData.data.landingButton4 === "Menu" && (
                            <a className={styles.a} href="/menus"> <button className={styles.giftBtn}>SEE MENUS</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "Gallery" && (

                            <a className={styles.a} href="/gallery"> <button className={styles.giftBtn}>GALLERY</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "ContactUs" && (

                            <a className={styles.a} href="/contact"> <button className={styles.giftBtn}>CONTACT US</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "Book" && (

<a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "Experience" && (
                            <a className={styles.a} href="/chefs-table-experience"> <button className={styles.giftBtn}>CHEF'S TABLE</button></a>

                        )

                        }

                        {reduxData.data.landingButton4 === "Careers" && (
                            <a className={styles.a} href="/careers"> <button className={styles.giftBtn}>CAREERS</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "Music" && (
                            <a className={styles.a} href="/music-at-connollys"> <button className={styles.giftBtn}>WHAT'S ON?</button></a>

                        )


                        }

                        {reduxData.data.landingButton4 === "Wine" && (
                            <a className={styles.a} href="/wine-experience-room"> <button className={styles.giftBtn}>WINE CAVE</button></a>


                        )

                        }

                        <div className={styles.hr}>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className={styles.row} ref={(el) => (descRef.current[2] = el)}>
                    <div className={styles.imgCont}>
                        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.landingImg5}`} alt="Night time, people peaking into Connolly's" />
                    </div>
                    <div className={styles.txtBox}>
                        <div className={styles.hr}>
                            <hr />
                        </div>
                        <p className={styles.specialP}>{reduxData.data.landingTitle6}</p>
                        {reduxData.data.landingButton5 === "Menu" && (
                            <a className={styles.a} href="/menus"> <button className={styles.giftBtn}>SEE MENUS</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "Gallery" && (

                            <a className={styles.a} href="/gallery"> <button className={styles.giftBtn}>GALLERY</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "ContactUs" && (

                            <a className={styles.a} href="/contact"> <button className={styles.giftBtn}>CONTACT US</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "Book" && (

<a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "Experience" && (
                            <a className={styles.a} href="/chefs-table-experience"> <button className={styles.giftBtn}>CHEF'S TABLE</button></a>

                        )

                        }

                        {reduxData.data.landingButton5 === "Careers" && (
                            <a className={styles.a} href="/careers"> <button className={styles.giftBtn}>CAREERS</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "Music" && (
                            <a className={styles.a} href="/music-at-connollys"> <button className={styles.giftBtn}>WHAT'S ON?</button></a>

                        )


                        }

                        {reduxData.data.landingButton5 === "Wine" && (
                            <a className={styles.a} href="/wine-experience-room"> <button className={styles.giftBtn}>WINE CAVE</button></a>


                        )

                        }

                        <div className={styles.hr}>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.hashtags}>
                <p>{reduxData.data.landingHastag1}</p> <p>{reduxData.data.landingHastag2}</p>

            </div>


        </div>
    )
}

export default HomeMain