import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getData } from './redux/websiteSlice'
import { getMenusData } from './redux/menusSlice';
import { getRouteData } from './redux/routeSlice';
import { getPrivacyData } from './redux/privacySlice';
import { getTermsData } from './redux/termsSlice';
import { getOpeningHoursData } from './redux/openingHoursSlice';
import { request } from './util/fetchAPI';
import Home from './pages/home/home';
import BookTable from './pages/bookTable/BookTable';
import Menus from './pages/menus/Menus';
import KitchenExperience from './pages/kitchenExperience/KitchenExperience';
import WineExperience from './pages/wineExperience/WineExperience';
import Music from './pages/music/Music';
import OurTeam from './pages/ourTeam/OurTeam';
import Contact from './pages/contact/Contact';
import Gifts from './pages/gifts/Gifts';
import GalleryPage from './pages/galleryPage/GalleryPage';
import Disclaimer from './pages/disclaimer/Disclaimer';
import TsAndCs from './pages/tsAndCs/TsAndCs';
import Privacy from './pages/privacy/Privacy';
import Careers from './pages/careers/Careers';

import Email from './pages/email/Email';
import Signin from './pages/signin/Signin';
import Signup from './pages/signup/Signup';
import CreateEvent from './pages/createEvent/CreateEvent';
import Festival from './pages/festival/Festival';
import Events from './pages/Events/Events';
import Valentines from './pages/valentines/Valentines';
import Mothers from './pages/mothers/Mothers';
import StPatricks from './pages/stPatricks/stPatricks';
import Login from './pages/login/Login';
import Admin from './pages/admin/Admin';
import ChefAdmin from './pages/chefAdmin/ChefAdmin';
import ChefLogin from './pages/chefLogin/ChefLogin';
import RouteDetail from './pages/routeDetail/RouteDetail';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { getMusicData } from './redux/musicSlice';
import { Helmet } from 'react-helmet';
import MusicGalleryPage from './pages/musicGalleryPage/MusicGalleryPage';

function App() {

  const [allEvents, setAllEvents] = useState([]);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const dispatch = useDispatch();










  // Preload the image



  useEffect(() => {
    const fetchAllEvents = async () => {
      const data = await request("/event/getAll", 'GET');
      setAllEvents(data)

      dispatch(getMusicData(data))

    };

    fetchAllEvents();
  }, []);



  const [blob, setBlob] = useState("")
  const [og, setOg] = useState("")


  useEffect(() => {

    const fetchWebsite = async () => {
      const data = await request("/website/getAll", 'GET');

      dispatch(getData(data[0]))

      console.log("Dispatched")
   
      setBlob(data[0].logoImg)
      setOg(data[0].ogImg)

    };



    fetchWebsite();

  }, []);




  const fetchMenus = async () => {
    const data = await request("/menus/getAll", 'GET');

    dispatch(getMenusData(data))

    console.log(data)

  };


  useEffect(() => {

    fetchMenus();

  }, [])



  const fetchRoutes = async () => {
    const data = await request("/routes/getAll", 'GET');

    dispatch(getRouteData(data))

    console.log(data)

  };


  useEffect(() => {

    fetchRoutes();

  }, [])




  useEffect(() => {

    const fetchPrivacy = async () => {
      const data = await request("/privacyPolicy/getAll", 'GET');

      dispatch(getPrivacyData(data[0]))

      console.log(data[0])

    };



    fetchPrivacy();

  }, []);



  useEffect(() => {

    const fetchTerms = async () => {
      const data = await request("/termsAndConditions/getAll", 'GET');

      dispatch(getTermsData(data[0]))

      console.log(data[0])

    };



    fetchTerms();

  }, []);



  useEffect(() => {

    const fetchOpeningHours = async () => {
      const data = await request("/openingHours/getAll", 'GET');

      dispatch(getOpeningHoursData(data[0].contents))

      console.log(data[0])



    };



    fetchOpeningHours();

  }, []);







 /* useEffect(() => {
    const setFavicon = async () => {

      if (blob !== "") {

        try {





          // Create a new link element
          const link = document.createElement('link');
          link.type = 'image/x-icon';
          link.rel = 'icon';

          // Create a URL for the blob
          link.href = https://connollysapi-n476.onrender.com/images/${blob}


          // Append the link element to the document head
          document.head.appendChild(link);
        } catch (error) {
          console.error('Error setting favicon:', error);
        }

      }



    };

    setFavicon();

    // Clean up function to revoke the object URL

  }, [blob]); // Empty dependency array ensures this effect runs only once after the initial render


*/




  return (
    <>


      <Router>
        <ScrollToTop>
          <Helmet>
            <link rel="icon" href={`https://connollysapi-n476.onrender.com/images/${blob}`} type="image/x-icon" />
            <meta property="og:image" content={`https://connollysapi-n476.onrender.com/images/${og}`} />
       
            {/* Add other dynamic meta tags as needed */}
          </Helmet>
          <Routes>
            <Route path="/" element={

              <Home />


            } />

            <Route path="/book-a-table" element={
              <BookTable />
            } />

            <Route path="/menus" element={
              <Menus />
            } />

            <Route path="/chefs-table-experience" element={
              <KitchenExperience />
            } />


            <Route path="/wine-experience-room" element={
              <WineExperience />
            } />

            <Route path="/admin-dashboard" element={
              <Admin />

            } />

            <Route path="/admin-login" element={
              <Login />
            } />


            <Route path="/chef-dashboard" element={
              <ChefAdmin />


            } />

            <Route path="/chef-login" element={
              <ChefLogin />

            } />


            <Route path="/event/:id" element={
              <RouteDetail />
            } />


            <Route path="/music-at-connollys" element={
              <Music newEvents={allEvents} />
            } />

            <Route path="/our-team" element={
              <OurTeam />
            } />

            <Route path="/cruinniú-festival" element={
              <Festival />
            } />


            <Route path="/mothers-day" element={

              <Mothers />
            } />

            <Route path="/stpatricks" element={
              <StPatricks />
            } />




            <Route path="/valentines-week" element={
              <Valentines />
            } />

            <Route path="/book-an-event" element={
              <Events />
            } />




            <Route path="/gallery" element={
              <GalleryPage />
            } />

<Route path="/music-gallery" element={
              <MusicGalleryPage />
            } />

            <Route path="/contact" element={
              <Contact />
            } />


            <Route path="/gifts-and-experiences" element={
              <Gifts />
            } />

            <Route path="/disclaimer" element={
              <Disclaimer />
            } />


            <Route path="/terms-and-conditions" element={
              <TsAndCs />
            } />


            <Route path="/privacy-policy" element={
              <Privacy />
            } />

            <Route path="/careers" element={
              <Careers />
            } />

            <Route path="/email" element={
              <Email />
            } />
        
            <Route path="/admin-signin" element={
              <Signin />
            } />

            <Route path="/event-management" element={
              <CreateEvent />
            } />

          </Routes>
        </ScrollToTop>
      </Router>
    </>

  );
}

export default App;